import { useMemo } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { FIELD_NAME_TO_TRANSLATION_LABEL, LineChartProps, timestampToReadable } from "./LineChartCommon";
import { useTranslation } from "react-i18next";
import { downRounding, upRounding } from "./LineChartCommon";

import _ from "lodash"

interface PowerLineChartProps extends LineChartProps {
    focusMode: boolean
}

const PowerLineChart = ({ lineData, currentDevice, ticks, lineChartXTickFormatter, focusMode }: PowerLineChartProps) => {

    const { t } = useTranslation("dashboard");

    const renderCustomizedTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const pt = lineData.find((elem: any) => elem.time === label);
            if (pt) {
                return (
                <div className="tooltip-container">
                    <p style={{ fontWeight: 700 }}>{lineChartXTickFormatter(parseInt(pt.time))}</p>
                    {payload.map((line: any, index: number) => (
                    <p key={index} style={{ color: line.stroke, fontWeight: 500 }}>
                        {t(line.name, { device_name: currentDevice?.name })}:{" "}
                        {pt[line.dataKey]} W
                    </p>
                    ))}
                </div>
                );
            }
        }
        return <></>;
    };

    const filteredLineData = useMemo(() => {
        if (focusMode) {
            const firstIdx = _.findIndex(lineData, (elem: any) => elem.soliseco_power > 20)
            const lastIdx = _.findLastIndex(lineData, (elem: any) => elem.soliseco_power > 20)
            if (firstIdx + 10 < lastIdx) {
                return lineData.slice(Math.max(0, firstIdx - 5), Math.min(lineData.length - 1, lastIdx + 5))
            }
        }
        return lineData
    }, [focusMode, lineData])

    const [dataMin, dataMax] = useMemo(() => {
        const globalMin = Math.min(...filteredLineData.map((pt: any) => Math.min(pt.power_balance, pt.power_balance_no_soliseco, pt.soliseco_power)))
        const globalMax = Math.max(...filteredLineData.map((pt: any) => Math.max(pt.power_balance, pt.power_balance_no_soliseco, pt.soliseco_power)))
        const min: number = Math.round((globalMin * 1.1))
        const roundedMin = min - (10 + (min % 10))
        const max: number = Math.round((globalMax * 1.1))
        const roundedMax =  max + (10 - (max % 10))
        return [Math.min(-100, parseInt(roundedMin.toFixed(0))), Math.max(100, parseInt(roundedMax.toFixed(0)))]
    }, [filteredLineData])

    const filteredTicks = useMemo(() => {
        if (filteredLineData) {
            if (filteredLineData.length > 1) {
                const start = filteredLineData[0].time
                const end = filteredLineData[filteredLineData.length - 1].time
                const roundedStart = upRounding(start, 900)
                const roundedEnd = downRounding(end, 900)
                const diff = roundedEnd - roundedStart
                if (diff > 3600) {
                const secondTick = downRounding(roundedStart + (diff / 3), 900)
                const thirdTick = downRounding(roundedStart + ((2 * diff) / 3), 900)
                return [roundedStart, secondTick, thirdTick, roundedEnd]

                }
                if (roundedEnd - roundedStart >= 900) {
                return [roundedStart, roundedEnd]
                }
            }
            return filteredLineData.map((elem: any) => elem.time)
        }
        return []
    }, [filteredLineData])

    const compareNumbers = (a: number, b: number) => {
        return a - b;
    }
    
    const yTicks = useMemo(() => {
        const interval = Math.round((Math.abs(dataMin) + Math.abs(dataMax)) / 4)
        const roundedInterval = interval + (10 - (interval % 10))
        const negativeTickCnt: number = Math.floor(Math.abs(dataMin) / roundedInterval)
        const positiveTickCnt: number = Math.floor(Math.abs(dataMax) / roundedInterval)
        const negativeTicks = Array.from({ length: negativeTickCnt }, (_, i) => { return -(i + 1) * roundedInterval })
        const positiveTicks = Array.from({ length: positiveTickCnt }, (_, i) => { return (i + 1) * roundedInterval })
        const ticks = [dataMin, ...negativeTicks, 0, ...positiveTicks, dataMax]
        const uniqueTicks = ticks.filter((value, index, array) => ticks.indexOf(value) === index);
        uniqueTicks.sort(compareNumbers)
        return uniqueTicks
    }, [dataMin, dataMax])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                style={{ marginLeft: "-5px" }}
                width={500}
                height={300}
                data={filteredLineData}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey='time'
                    name='Time'
                    scale='time'
                    type='number'
                    domain={['dataMin', 'dataMax']} 
                    tickFormatter={ lineChartXTickFormatter }
                    ticks={filteredTicks}
                />
                <YAxis
                    unit={"W"}
                    domain={ () => [dataMin, dataMax] }
                    ticks={yTicks}
                />
                <Tooltip content={renderCustomizedTooltip} />
                <Legend
                    formatter={(value) =>
                    t(FIELD_NAME_TO_TRANSLATION_LABEL[value], {
                        device_name: currentDevice?.name,
                    })
                    }
                />
                <Line
                    type="monotone"
                    dataKey={"power_balance_no_soliseco"}
                    stroke="#012124"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
                <Line
                    type="monotone"
                    dataKey={"power_balance"}
                    stroke="#F7AA3F"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
                <Line
                    type="monotone"
                    dataKey={"soliseco_power"}
                    stroke="#82BA71"
                    activeDot={{ r: 4 }}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default PowerLineChart
