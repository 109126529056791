import { getTimezonesForCountry, CountryCode } from "countries-and-timezones";
import { Device } from "../../../../lib/Device";
import dayjs from 'dayjs';

export const FIELD_NAME_TO_TRANSLATION_LABEL: any = {
  power_balance: "power_balance",
  power_balance_no_soliseco: "power_balance_no_soliseco",
  soliseco_power: "soliseco_power",
  u_1: "u_1",
  u_2: "u_2",
  u_3: "u_3",
  water_temperature: "water_temperature",
  dropout_v_max: "dropout_v_max",
  min_planning_temp: "min_planning_temp"
};

export interface LineChartProps {
  lineData: any[];
  currentDevice?: Device;
  ticks: any[];
  lineChartXTickFormatter: any;
}

export const timestampToReadable = (val: number, country: CountryCode) => {
  const homeTimezone = getTimezonesForCountry(country)
  const date = dayjs.unix(val).tz(homeTimezone[0].name)
  let day = date.day();
  let month = date.month() + 1;
  let hours = date.hour();
  let minutes = date.minute();

  if (minutes > 53) {
    hours += 1;
  }
  if (hours >= 24) {
    day += 1;
  }
  if (day > new Date(date.year(), month, 0).getDate()) {
    month += 1;
  }
  if (month >= 12) {
    month = 1;
  }

  return `${day.toString().padStart(2, "00")}-${month
    .toString()
    .padStart(2, "00")} ${hours.toString().padStart(2, "00")}h${minutes
    .toString()
    .padStart(2, "00")}`;
}

export const upRounding = (n: number, step: number) => {
  if (n % step === 0) {
    return n
  }
  return n + (step - (n % step))
}
  
export const downRounding = (n: number, step: number) => {
  if (n % step === 0) {
    return n
  }
  return n - (n % step)
}
